// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alberghi-e-agriturismi-js": () => import("/opt/build/repo/src/pages/alberghi-e-agriturismi.js" /* webpackChunkName: "component---src-pages-alberghi-e-agriturismi-js" */),
  "component---src-pages-azienda-e-partner-js": () => import("/opt/build/repo/src/pages/azienda-e-partner.js" /* webpackChunkName: "component---src-pages-azienda-e-partner-js" */),
  "component---src-pages-certificazione-energetica-js": () => import("/opt/build/repo/src/pages/certificazione-energetica.js" /* webpackChunkName: "component---src-pages-certificazione-energetica-js" */),
  "component---src-pages-cogenerazione-js": () => import("/opt/build/repo/src/pages/cogenerazione.js" /* webpackChunkName: "component---src-pages-cogenerazione-js" */),
  "component---src-pages-contatti-js": () => import("/opt/build/repo/src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-eternit-e-fotovoltaico-js": () => import("/opt/build/repo/src/pages/eternit-e-fotovoltaico.js" /* webpackChunkName: "component---src-pages-eternit-e-fotovoltaico-js" */),
  "component---src-pages-form-ok-js": () => import("/opt/build/repo/src/pages/form-ok.js" /* webpackChunkName: "component---src-pages-form-ok-js" */),
  "component---src-pages-fotovoltaico-aziendale-js": () => import("/opt/build/repo/src/pages/fotovoltaico-aziendale.js" /* webpackChunkName: "component---src-pages-fotovoltaico-aziendale-js" */),
  "component---src-pages-fotovoltaico-con-accumulo-js": () => import("/opt/build/repo/src/pages/fotovoltaico-con-accumulo.js" /* webpackChunkName: "component---src-pages-fotovoltaico-con-accumulo-js" */),
  "component---src-pages-fotovoltaico-residenziale-js": () => import("/opt/build/repo/src/pages/fotovoltaico-residenziale.js" /* webpackChunkName: "component---src-pages-fotovoltaico-residenziale-js" */),
  "component---src-pages-fuel-cells-js": () => import("/opt/build/repo/src/pages/fuel-cells.js" /* webpackChunkName: "component---src-pages-fuel-cells-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingegneria-energetica-js": () => import("/opt/build/repo/src/pages/ingegneria-energetica.js" /* webpackChunkName: "component---src-pages-ingegneria-energetica-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("/opt/build/repo/src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-mobilita-sostenibile-js": () => import("/opt/build/repo/src/pages/mobilita-sostenibile.js" /* webpackChunkName: "component---src-pages-mobilita-sostenibile-js" */),
  "component---src-pages-nuovi-incentivi-fotovoltaico-2019-js": () => import("/opt/build/repo/src/pages/nuovi-incentivi-fotovoltaico-2019.js" /* webpackChunkName: "component---src-pages-nuovi-incentivi-fotovoltaico-2019-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-richiesta-informazioni-js": () => import("/opt/build/repo/src/pages/richiesta-informazioni.js" /* webpackChunkName: "component---src-pages-richiesta-informazioni-js" */),
  "component---src-pages-richiesta-preventivo-js": () => import("/opt/build/repo/src/pages/richiesta-preventivo.js" /* webpackChunkName: "component---src-pages-richiesta-preventivo-js" */),
  "component---src-pages-servizi-di-ingegneria-js": () => import("/opt/build/repo/src/pages/servizi-di-ingegneria.js" /* webpackChunkName: "component---src-pages-servizi-di-ingegneria-js" */),
  "component---src-pages-solare-termodinamico-js": () => import("/opt/build/repo/src/pages/solare-termodinamico.js" /* webpackChunkName: "component---src-pages-solare-termodinamico-js" */),
  "component---src-pages-termografia-js": () => import("/opt/build/repo/src/pages/termografia.js" /* webpackChunkName: "component---src-pages-termografia-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

